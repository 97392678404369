import React from 'react'
// import axios from 'axios'
// import styled from '@emotion/styled'
// import { graphql, navigate } from 'gatsby'
import { connect } from 'react-redux'
// import { SEO, Header } from '../components'
// import website from '../../config/website'
// import {
//   CartWrapper,
//   Title,
//   PaymentForm,
//   PaymentNotice,
//   Cards
// } from '../components/Style/cartStyle';
// import ShopBreadcrumb from '../components/ShopBreadcrumb'
// import CheckoutForm from '../components/CheckoutForm'
import { clearCart } from '../actions/cart';

// const PaymentMethod = styled.div`
//   position: relative;
//   box-shadow: 0 6px 9px rgb(50 50 93 / 6%), 0 2px 5px rgb(0 0 0 / 8%), inset 0 1px 0 #ddd;
//   background-color: #fefefe;
//   border-radius: 4px;
//   max-width: 500px;
//   padding: 2rem;
//   margin: 5rem auto 0;
//   h3, p {
//     text-align: center;
//     font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
//     sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
//     small {
//       display: block;
//       font-style: italic;
//     }
//   }
//   p:last-of-type {
//     margin: 0;
//   }
// `;

// const InfoMessage = styled.div`
//   position: relative;
//   box-shadow: 0 6px 9px rgb(50 50 93 / 6%), 0 2px 5px rgb(0 0 0 / 8%), inset 0 1px 0 #ddd;
//   background-color: #fefefe;
//   border-radius: 4px;
//   max-width: 500px;
//   padding: 2rem;
//   margin: 5rem auto 0;
//   p {
//     text-align: center;
//     font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
//     sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
//     small {
//       display: block;
//       font-style: italic;
//     }
//   }
//   p:last-of-type {
//     margin: 0;
//   }
// `;

class Payment extends React.Component {
  
  // state = {
  //   catalog: false
  // }

  // constructor() {
  //   super();

  //   let loaded = false;
  //   if (typeof localStorage !== 'undefined') {
  //     const orderPath = JSON.parse(localStorage.getItem('dischr-order-path'));
  //     if (!orderPath) {
  //       navigate('/shop/');
  //     } else {
  //       if (orderPath.indexOf('payment') < 0) {
  //         localStorage.setItem('dischr-order-path', JSON.stringify(['cart', 'delivery', 'payment']));
  //       }
  //       loaded = true;
  //     }
  //   }

  //   this.state = {
  //     balance: 0,
  //     _loaded: loaded,
  //     bankTransferProcessing: false,
  //     bankTransferDisabled: false,
  //     orderid: null
  //   };
  // }

  // componentDidMount() {
  //   const search = this.props.location.search.split('=');
  //   const isCatalog = (search[0].replace('?', '') === 'catalog' && search[1] === '1');
  //   this.setState({ catalog: isCatalog })
    
  //   if (this.state.balance === 0) {
  //     if (this.props.items.length > 0) {
  //       const reducer = (accumulator, currentValue) => accumulator + currentValue;
  //       const amounts = this.props.items.map(i => i.amount);
  //       this.setState({ balance: amounts.reduce(reducer)});
  //     }
  //   }
  // }

  // componentDidUpdate() {
  //   if (this.state.balance === 0) {
  //     if (this.props.items.length > 0) {
  //       const reducer = (accumulator, currentValue) => accumulator + currentValue;
  //       const amounts = this.props.items.map(i => i.amount);
  //       this.setState({ balance: amounts.reduce(reducer)});
  //     }
  //   }
  // }

  // retrieveDeliveryInformations = () => {
  //   if (typeof localStorage !== 'undefined') {
  //     const infos = JSON.parse(localStorage.getItem('dischr-cart-delivery'));
  //     return infos;
  //   }
  // }

  // confirmOrder = () => {
  //   this.setState({ 
  //     bankTransferProcessing: true, 
  //     bankTransferDisabled: true
  //   })
  //   axios.post(process.env.CHECKOUT_ENDPOINT, {
  //     billing: this.retrieveDeliveryInformations(),
  //     cart: this.props.items,
  //     bankTransfer: true
  //   }, {
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'X-Api-Key': process.env.CHECKOUT_API_KEY
  //     }
  //   }).then(res => {
  //     this.setState({ 
  //       bankTransferProcessing: false,
  //       orderid: res.data.orderId
  //     })
  //     this.props.clearCart();
  //   })
  // }

  render() {
    // const {
    //   location,
    //   pageContext: { locale },
    //   data: { cards, lock }
    // } = this.props;

    // const {
    //   bankTransferProcessing,
    //   bankTransferDisabled,
    // } = this.state;
    
    // const context = { slug: 'payment', parent: 'shop' };
    // const billingDetails = this.retrieveDeliveryInformations();
    return (
      <React.Fragment>
        {/* <SEO title={`Disch Remy - Payment`} pathname={location.pathname} locale={locale} />
        <Header {...context} />
        {
          this.state._loaded && (
            <React.Fragment>
              <ShopBreadcrumb {...context} catalog={this.state.catalog} />
              <CartWrapper id={website.skipNavId}>
                <React.Fragment>
                  <Title style={{
                    maxWidth: '500px', 
                    margin: '0 auto 0', 
                    padding: '0 15px', 
                    textAlign: 'center', 
                    textTransform: 'uppercase'
                  }}><h2>Payment</h2></Title>
                  {
                    (this.state.balance && !this.state.orderid) > 0 && (
                      <PaymentForm>
                        <CheckoutForm 
                          infos={billingDetails} 
                          balance={this.state.balance} 
                          cart={this.props.items} 
                          clearCart={this.props.clearCart}
                        />
                        {
                          this.props.items.length > 0 && (
                            <PaymentNotice>
                              <p>
                                <span>
                                  <span className="lock"><img src={lock.publicURL} /></span>
                                  <span className="centered">SSL secured website</span>
                                </span>
                              </p>
                              <hr />
                              <Cards>
                                <ul>
                                  {
                                    cards.edges.map((i, index) => {
                                      return (
                                        <li key={`card-${index}`}>
                                          <img src={i.node.publicURL} />
                                        </li>
                                      )
                                    })
                                  }
                                </ul>
                              </Cards>
                              <p>
                                <span>
                                  <span>Powered by Stripe</span>
                                </span>
                              </p>
                            </PaymentNotice>
                          )
                        }
                      </PaymentForm>
                    )
                  }
                  <InfoMessage style={{
                    display: this.state.orderid ? 'block' : 'none'
                  }}>
                    <p>
                      Your order has been processed, you will soon receive an email confirming its preparation.
                      <br />Thank you.
                    </p>
                  </InfoMessage>
                  <PaymentMethod>
                    <h3>Bank transfer payment</h3>
                    <p>
                      If you prefer to pay by bank transfer, please send your payment to the following account :
                      <small>
                        (If you encounter a problem or have a question about the bank transfer, feel free to contact us 
                        at davidbraud@dischr.com)
                      </small>
                    </p>
                    <hr />
                    <p style={{textAlign: 'left'}}>
                      Account holder name : <strong>M. DISCH REMY</strong><br />
                      IBAN : <strong>FR7618206002785520087200157</strong><br />
                      AMOUNT : <strong>{this.state.balance},00 €</strong><br /><br />
                      REFERENCE : <strong>{
                        this.state.orderid ? (
                          <React.Fragment>{this.state.orderid}</React.Fragment>
                        ) : (
                          <React.Fragment>
                            please confirm your order to generate your order reference and hold artworks you wish to purchase.
                          </React.Fragment>
                        )
                      }</strong>
                      <small>(indicate your order reference in the description of your transfer or send it to davidbraud@dischr.com with the proof of your bank transfer)</small>
                    </p>

                    <PaymentForm>
                      <button
                        className={`SubmitButton`}
                        type="submit"
                        disabled={bankTransferProcessing || bankTransferDisabled}
                        onClick={this.confirmOrder}
                      >
                        {bankTransferProcessing ? 'Processing...' : 'Confirm and get order reference'}
                      </button>
                    </PaymentForm>
                  </PaymentMethod>
                </React.Fragment>
              </CartWrapper>
            </React.Fragment>
          )
        } */}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  items: state.cart
});
const mapDispatchToProps = (dispatch) => ({
  clearCart: () => dispatch(clearCart())
});
export default connect(mapStateToProps, mapDispatchToProps)(Payment);

// export const pageQuery = graphql`
//   query PaymentPage {
//     cards: allFile(filter: {relativeDirectory: {eq: "cards"}}) {
//       edges {
//         node {
//           publicURL
//         }
//       }
//     }
//     lock: file(name: {eq: "lock-solid"}) {
//       publicURL
//     }
//   }
// `
